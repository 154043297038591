<template>
  <component :is="loaded === false ? 'div' : 'div'">
    <b-card-actions
        ref="refreshCard"
        action-back
        action-save
        @back="hRedirect('apps-experts-events-list', { id: $route.params.expertid})"
        @save="createItem"
        :title="cardTitle"
        no-body
        disable-loading-on-refresh
        :loaded.snyc="loaded"
    >
    </b-card-actions>
    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form
          @submit.stop.prevent="handleSubmit(createItem)"
          @reset.prevent="resetForm"
      >
        <b-card>
          <div class="mb-2">
            <b-form-checkbox
                v-model="currentItem.status"
                value="1"
                name="status"
                class="custom-control-success"
            >
              {{ $t('Active') }}
            </b-form-checkbox>
          </div>

          <validation-provider
              #default="validationContext"
              :name="$t('Event Type')"
              rules="required"
          >
            <b-form-group :label="$t('Event Type')">
              <b-form-select
                  v-model="currentItem.event_type_id"
                  :options="eventTypesOptions"
                  :placeholder="$t('Please select an Event Type')"
              >
              </b-form-select>
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('event_type_id')" class="text-danger"
              >{{ $t(validation.event_type_id[0]) }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
              #default="validationContext"
              :name="$t('Name')"
              rules="required"
          >
            <b-form-group :label="$t('Name')" label-for="street" class="mb-1">
              <b-form-input
                  v-model="currentItem.name"
                  :name="$t('Name')"
                  id="street"
                  :placeholder="$t('Name')"
              />
              <small class="text-danger">{{ validationContext.errors[0] }}</small>
              <small v-if="validate && Object.keys(validation).includes('name')" class="text-danger"
              >{{ $t(validation.name[0]) }}</small>
            </b-form-group>
          </validation-provider>

          <b-row>
            <b-col md="6" xl="6" class="mb-1 mt-2">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Credits')"
                  rules="required"
              >
                <b-form-group :label="$t('Credits')">
                  <b-form-input
                      v-model="currentItem.credits"
                      :name="$t('Credits')"
                      type="number"
                      id="credits"
                      :placeholder="$t('Credits')"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('credits')" class="text-danger"
                  >{{ $t(validation.credits[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col md="6" xl="6" class="mb-1 mt-2">
              <validation-provider
                  #default="validationContext"
                  :name="$t('Booking time in min')"
                  rules="required"
              >
                <b-form-group :label="$t('Booking time in min')">
                  <b-form-input
                      type="number"
                      v-model="currentItem.booking_time"
                      :name="$t('Booking time in min')"
                      id="Boking time"
                      :placeholder="$t('Booking time in min')"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                  <small v-if="validate && Object.keys(validation).includes('booking_time')" class="text-danger"
                  >{{ $t(validation.booking_time[0]) }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <b-form-group
              class="mt-2"
              :label="$t('Description')"
              label-for="Description"
          >
            <quill-editor
                v-model="currentItem.description"
                :options="editorOption"
            >
              <div id="toolbar" slot="toolbar">
                <!-- Add a bold button -->
                <button class="ql-bold">Bold</button>
                <button class="ql-italic">Italic</button>

                <!-- Add font size dropdown -->

                <select class="ql-size">
                  <option value="small"/>
                  <option selected/>
                  <option value="large"/>
                  <option value="huge"/>
                </select>

                <!-- Add subscript and superscript buttons -->
                <button class="ql-script" value="sub"/>
                <button class="ql-script" value="super"/>
              </div>
            </quill-editor>
          </b-form-group>
        </b-card>
        <b-card>
          <b-button
              variant="success"
              type="submit"
          >
            {{ $t('Save') }}
          </b-button>
        </b-card>
      </b-form>
    </validation-observer>
  </component>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BAlert,
  BToast,
  BLink,
  BCard,
  BRow,
  BCol,
  BFormCheckbox,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  BFormCheckboxGroup,
  BFormFile,
  BAvatar,
  BMedia,
  BFormSelect,

} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref, onUnmounted, mounted } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import formValidation from '@core/comp-functions/forms/form-validation'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import { required, alphaNum, email } from '@validations'
import BCardActions from '@core/components/b-card-actions/BCardActions'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BAlert,
    BToast,
    BLink,
    BCard,
    BRow,
    BCol,
    BFormCheckbox,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    BFormCheckboxGroup,
    vSelect,
    BFormFile,
    BAvatar,
    BMedia,
    quillEditor,
    BFormSelect,
    BCardActions,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#toolbar',
        },
      },

      currentItem: {
        status: 1,
        event_type_id: [],
        name: '',
        description: '',
        credits: '',
        booking_time: '',
      },
      eventTypesOptions: [],
      loaded: true,
      validation: {},
      validate: false,
    }
  },

  methods: {
    createItem() {
      let url = `/experts/${this.$route.params.expertid}/events`
      this.$refs.refFormObserver.validate()
          .then((success) => {
            this.validation = {}
            this.validate = false

            if (success) {
              this.helperAddItem(`/experts/${this.$route.params.expertid}/events`, this.currentItem, this.$refs.refFormObserver, null, (response) => {
                this.$router.push(
                    {
                      name: 'apps-experts-events-edit',
                      params: {
                        expertid: this.$route.params.expertid,
                        id: response.data.id
                      },
                    },
                    () => setTimeout(() => {
                      this.hToast('success', 'Success', 'Item saved')
                    })
                )
              })
            } else {
              this.loaded = false
              setTimeout(() => {
                this.loaded = true
              })
            }
          })
    },

    getEventTypes() {
      this.$http
          .get(`/eventtypes`)
          .then((response) => {
            let options = [
              {
                value: null,
                text: this.$i18n.t('Please select an event type')
              },
            ]
            response.data.items.forEach(function (item) {
              options.push({
                text: item.name,
                value: item.id,
              })
            })
            this.eventTypesOptions = options
          })
          .catch((error) => {
            console.log(error)
          })
    },

    getValidationState({
      dirty,
      validated,
      valid = null
    }) {
      return dirty || validated ? valid : null
    },
  },
  mounted() {
    this.getEventTypes()
    this.$http.get(`/experts/${this.$route.params.expertid}`)
        .then(response => {
          this.cardTitle = this.currentItem.name + ' (' + response.data.firstname + ' ' + response.data.name + ')'
        })
  },
}
</script>

<style>
.quill-editor .ql-container {
  height: 250px;
}
</style>
